import { useAppDisplayModeContext, useStyles } from '@archipro-design/aria';
import { useIsSafari } from '@modules/root/hook/use-is-safari';
import { EnquiryInline } from '~/modules/enquiry/component/enquiry-inline/EnquiryInline';
import type { FavouriteItem } from '~/modules/design-board/type';
import { LoginEnquiryInline } from '~/modules/enquiry/component/login-enquiry-inline/LoginEnquiryInline';
import type { EnquiryProfessional } from '~/modules/enquiry/component/enquiry-form/EnquiryForm';
import { useABTestValue } from '~/modules/root/hook/use-growthbook';
import type { EnquiryItemType } from '../tracking/util/trackEnquireReveal';
import * as S from './CompanyProfile.style';

interface CompanyEnquiryFormProps {
    Professional: EnquiryProfessional;
    messageBodyPlaceholder?: string;
    siteTreeID: number;
    favouriteItem?: FavouriteItem;
    showCustomEnquiryText?: boolean;
    itemType: EnquiryItemType;
}

const CompanyEnquiryForm = ({
    Professional,
    messageBodyPlaceholder,
    siteTreeID,
    favouriteItem,
    showCustomEnquiryText = false,
    itemType,
}: CompanyEnquiryFormProps) => {
    const isSafari = useIsSafari();
    const { css } = useStyles({ isSafari });

    const enquiryVariantControl = useABTestValue('upgraded-enquiry-flow');
    const isControlVariant = enquiryVariantControl === 'control';

    const { mobile: isMobile } = useAppDisplayModeContext();

    return (
        <div
            style={{ scrollBehavior: 'smooth' }}
            className={css(S.AnchorContainer)}
        >
            {isControlVariant ? (
                <EnquiryInline
                    professional={Professional}
                    submitButtonText={
                        isMobile
                            ? 'SEND'
                            : Professional.primaryCTA?.primaryCTALabel
                    }
                    messageBodyPlaceholder={messageBodyPlaceholder}
                    siteTreeID={siteTreeID}
                    showCustomEnquiryText={showCustomEnquiryText}
                    favouriteItem={favouriteItem}
                    itemType={itemType}
                />
            ) : (
                <LoginEnquiryInline
                    professional={Professional}
                    enquiryVariantControl={enquiryVariantControl}
                    messageBodyPlaceholder={messageBodyPlaceholder}
                    siteTreeID={siteTreeID}
                    showCustomEnquiryText={showCustomEnquiryText}
                    itemType={itemType}
                />
            )}
        </div>
    );
};

export default CompanyEnquiryForm;
